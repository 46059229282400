@import "../../node_modules/swiper/swiper-bundle.min.css";

@font-face {
  font-family: "Poppins";
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium"), local("Poppins-Medium"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Semi Bold"), local("Poppins-Semi-Bold"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  line-height: 1.3;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
  -webkit-text-size-adjust: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  width: 100%;
  min-width: 320px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #2a2b2e;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

h1,
.title-2,
.title-1,
h2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
  margin: 0;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  line-height: 1.286em;
}

h1,
.title-2,
.title-1,
h2 {
  font-size: 56px;
  margin-bottom: 16px;
}

h3,
.title-3 {
  font-size: 48px;
  line-height: 1.292em;
  margin-bottom: 16px;
}

h4,
.title-4 {
  font-size: 34px;
  margin-bottom: 8px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: 1.6em;
  margin: 0;
  background-color: transparent;
}

a:visited {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img,
picture,
source {
  max-width: 100%;
  width: 100%;
  display: block;
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input {
  line-height: 1em;
}

button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: hidden;
  resize: none;
}

textarea:focus {
  outline: none;
}

label {
  cursor: pointer;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

p,
li {
  margin: 0;
  color: inherit;
  line-height: 2em;
  letter-spacing: -0.016em;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

section.section {
  position: relative;
  overflow: hidden;
}

tr {
  line-height: 1.5em;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

input[type=date] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: relative;
  right: -10px;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #000;
}

textarea::-moz-placeholder,
input::-moz-placeholder {
  font-size: 16px;
  color: #000;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  font-size: 16px;
  color: #000;
}

textarea::placeholder,
input::placeholder {
  font-size: 16px;
  color: #000;
}

input {
  outline: none;
}

.main-wrapper {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.main,
main {
  display: block;
  position: relative;
}

.locked {
  overflow: hidden;
}

.btns-row {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 40px 0 60px 0;
  position: relative;
}

.btns-row_center {
  justify-content: center;
}

.btn {
  text-align: center;
  display: inline-block;
}

.js-scroll {
  position: relative;
  transition: opacity 0.6s ease 0.2s, transform 0.8s ease 0.3s;
  will-change: transform;
  transition-delay: 0.2s;
}

.js-scroll.in-view {
  transform: translate(0, 0);
  opacity: 1;
}

.js-scroll.in-view-detect {
  transform: translate(0, 20px);
  opacity: 0;
}

.js-show {
  position: relative;
  transition: transform 0.8s ease, opacity 0.8s ease;
  will-change: transform;
}

.js-show.in-view {
  opacity: 1;
}

.js-show.in-view .plate {
  transition: transform 0.8s ease, opacity 0.8s ease;
  transform: scale(1) rotate(0);
  opacity: 1;
}

.js-show.in-view .plate._2 {
  transition-delay: 0.2s;
}

.js-show.in-view .plate._3 {
  transition-delay: 0.4s;
}

.js-show.in-view-detect {
  opacity: 0;
}

.js-show.in-view-detect .plate {
  transform: scale(0.5) rotate(45deg);
  opacity: 0;
}

.button {
  display: inline-block;
  background-color: #e01f54;
  color: #ffffff;
  border-radius: 40px;
  padding: 14px 53px;
  border: 1px solid #e01f54;
  font-weight: 600;
  transition: color 0.4s ease, background-color 0.4s ease;
}

.button:hover {
  background-color: #d31d4f;
}

.header {
  padding: 42px 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  transition: 0.2s ease;
}

.header.fixed {
  position: fixed;
  padding: 20px 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(28, 15, 15, 0.08);
}

.header.fixed .header__nav {
  opacity: 1;
  pointer-events: initial;
}

.header.in-view-detect {
  transform: translate(0, -20px);
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-row .btns-row {
  margin: 0;
}

.header-row .btns-row .btn {
  height: 40px;
}

.header-row .btns-row .btn img {
  height: 100%;
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 100%;
  margin-left: 80px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 100%;
  margin-left: 80px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.logo {
  display: inline-block;
  margin-top: -10px;
  position: relative;
  z-index: 1;
  flex: 0 0 136px;
  width: 136px;
}

.nav-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.nav-list__item:not(:last-child),
.nav-list li:not(:last-child) {
  margin-right: 12px;
}

.nav-list__link,
.nav-list a {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 18px;
}

.burger {
  position: relative;
  width: 22px;
  height: 16px;
  z-index: 101;
  display: none;
}

.burger_item {
  position: absolute;
  width: 22px;
  height: 2px;
  background-color: #000;
  left: 0;
  transition: 0.5s ease-out;
  border-radius: 10px;
}

.burger_item:first-child {
  top: 0;
}

.burger_item:nth-child(2) {
  top: 50%;
  margin-top: -1px;
}

.burger_item:last-child {
  bottom: 0;
}

.burger.active .burger_item:first-child {
  transform: rotate(45deg);
  top: 7px;
}

.burger.active .burger_item:nth-child(2) {
  width: 0;
}

.burger.active .burger_item:last-child {
  transform: rotate(-45deg);
  bottom: 7px;
}

.footer {
  background-color: #171717;
  color: #ffffff;
}

.footer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 56px 0;
}

.footer-logo {
  width: 128px;
}

.footer-menu {
  display: flex;
  align-items: center;
}

.footer-menu__item:not(:last-child) {
  margin-right: 67px;
}

.footer_bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px 0;
  text-align: center;
  font-size: 12px;
  line-height: 2.66em;
  color: rgba(255, 255, 255, 0.4);
}

.hero {
  padding-top: 80px;
}

.hero::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f4f1f2;
}

.hero .container {
  position: relative;
}

.hero__content {
  max-width: 50%;
  padding: 240px 0 295px 0;
  position: relative;
  z-index: 1;
}

.hero__content p {
  font-size: 18px;
  line-height: 1.778em;
  max-width: 530px;
  letter-spacing: -0.016em;
  margin-bottom: 10px;
  color: #232427;
  line-height: 32px;
}

.hero .btns-row {
  margin: 56px 0;
}

.hero__img {
  position: absolute;
  right: 14%;
  top: 50%;
  max-width: 310px;
  transform: translate(0, -55%);
  border-radius: 100%;
  box-shadow: 0px 0px 0px 0px rgba(1, 2, 18, 0.2), -6px 13px 31px 0px rgba(1, 2, 18, 0.2), -25px 50px 56px 0px rgba(1, 2, 18, 0.2), -57px 113px 76px 0px rgba(1, 2, 18, 0.06), -101px 202px 90px 0px rgba(1, 2, 18, 0.02), -159px 315px 99px 0px rgba(1, 2, 18, 0);
  background-color: #f4f1f2;
}

.hero__img::before {
  content: "";
  width: 200%;
  right: -20%;
  padding-bottom: 200%;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border-right: 1px solid #e7e5e6;
  z-index: -1;
}

.lemon {
  position: absolute;
}

.lemon._1 {
  bottom: -6%;
  width: 22%;
  left: -47%;
}

.lemon._2 {
  bottom: 64%;
  width: 23%;
  right: -41%;
}

.leave {
  position: absolute;
  z-index: -1;
  transition: transform 0.1s ease;
}

.leave._1 {
  bottom: -12%;
  left: 7%;
  width: 36%;
}

.leave._2 {
  bottom: 25%;
  right: -52%;
  width: 26%;
}

.leave._3 {
  top: 18%;
  left: -18%;
  width: 32%;
}

.plate {
  position: absolute;
  z-index: -1;
}

.plate._1 {
  top: -14%;
  right: -29%;
  width: 70%;
  overflow: hidden;
}

.plate._2 {
  bottom: 19%;
  left: -47%;
  width: 79%;
}

.plate._3 {
  bottom: -20%;
  right: -26%;
  width: 79%;
}

.dots {
  position: absolute;
  top: -8%;
  left: -27%;
  width: 43%;
  z-index: -1;
}

.awards {
  padding: 90px 0;
}

.awards__row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 -15px;
}

.awards__col {
  padding: 0 15px;
  flex: 0 0 300px;
}

.awards-card {
  position: relative;
  text-align: center;
  padding: 0 37px;
}

.awards-card::before,
.awards-card::after {
  content: "";
  width: 37px;
  height: 97px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
}

.awards-card::before {
  background-image: url("../img/leaves-left.svg");
  left: 0;
}

.awards-card::after {
  background-image: url("../img/leaves-right.svg");
  right: 0;
}

.awards-card span {
  font-size: 56px;
  display: block;
  font-weight: 700;
}

.awards-card span small {
  font-size: 36px;
  display: inline-block;
  margin-left: 10px;
}

.awards-card p {
  font-size: 18px;
}

.info {
  padding: 13px 0 180px;
}

.info__row {
  display: flex;
  margin: 0 -15px;
}

.info__row_middle .info__content {
  margin: 220px 0 0 42px;
}

.info__row_middle .info__img {
  margin: 80px 53px 32px auto;
}

.info__row_middle .info__img::after {
  background-image: url(../img/dots-yellow.svg);
  right: -54px;
  bottom: -57px;
  left: auto;
}

.info__row_middle .info__img::before {
  background-color: #f8e19f;
  right: -32px;
  left: auto;
}

.info__row_bottom {
  padding-top: 80px;
}

.info__row_bottom .info__img::before {
  background-color: #cde8f7;
}

.info__row_bottom .info__img::after {
  top: -10px;
  left: -62px;
  background-image: url(../img/dots-blue.svg);
}

.info__col {
  padding: 0 15px;
  flex: 0 0 50%;
}

.info__img {
  max-width: 463px;
  position: relative;
  margin: 40px 0 32px 72px;
}

.info__img::before {
  content: "";
  width: 95%;
  height: 89%;
  border-radius: 32px;
  background-color: #e1e8a3;
  position: absolute;
  z-index: -1;
  bottom: -32px;
  left: -32px;
}

.info__img::after {
  content: "";
  width: 128px;
  height: 128px;
  position: absolute;
  background-image: url(../img/dots-green.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  left: -75px;
  bottom: -50px;
  z-index: -1;
}

.info__img > img {
  border-radius: 40px;
}

.info__details {
  position: absolute;
  right: -23%;
  top: -35px;
}

.info-card {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: #ffffff;
  width: 267px;
  padding: 24px 18px;
  white-space: nowrap;
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.08), 4px 57px 34px 0px rgba(0, 0, 0, 0.05), 7px 100px 40px 0px rgba(0, 0, 0, 0.01), 11px 157px 44px 0px rgba(0, 0, 0, 0), -49px 1px 24px 0px rgba(0, 0, 0, 0.05);
}

.info-card:not(:last-child) {
  margin-bottom: 16px;
}

.info-card img {
  margin-right: 12px;
  width: 32px;
  height: 32px;
}

.info-card p {
  color: #1f3455;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.016em;
}

.info__content {
  max-width: 530px;
  margin: 155px 0 0 113px;
}

.info__content p {
  color: #535354;
}

.info__graph {
  position: absolute;
  top: -30px;
  left: -100px;
  max-width: 270px;
}

.info__diet {
  display: flex;
  position: absolute;
  bottom: -80px;
  left: -70px;
  margin: 0 -8px;
}

.diet-card {
  width: 11.5vw;
  max-width: 132px;
  height: 124px;
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.08), 4px 57px 34px 0px rgba(0, 0, 0, 0.05), 7px 100px 40px 0px rgba(0, 0, 0, 0.01), 11px 157px 44px 0px rgba(0, 0, 0, 0), -49px 1px 24px 0px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  padding: 22px 19px;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.diet-card:nth-child(2) {
  z-index: 1;
}

.diet-card:nth-child(3) {
  z-index: 2;
}

.diet-card:nth-child(4) {
  z-index: 3;
}

.diet-card img {
  width: 38px;
  margin: 0 auto 16px auto;
}

.diet-card__col {
  padding: 0 8px;
}

.testimonials {
  padding-top: 76px;
  background-color: #f4f1f2;
}

.testimonials__title {
  max-width: 810px;
  margin: 0 auto 40px auto;
  text-align: center;
}

.testimonials__title h2 {
  margin-bottom: 20px;
  color: #2a2b2e;
}

.testimonials__title p {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.016em;
  flex-wrap: wrap;
  color: #2a2b2e;
}

.testimonials__content {
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-auto-rows: 17px;
  height: 662px;
}

.testimonials__content::before {
  content: "";
  width: 100%;
  height: 316px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(244, 241, 242, 0) 1.19%, #f4f1f2 91.54%);
}

.testimonials-card {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 20px;
  max-width: 416px;
  box-shadow: 0px 23px 35px 0px rgba(166, 171, 189, 0.16);
}

.testimonials-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}

.testimonials-card__content {
  margin-bottom: 7px;
}

.testimonials-card__content p {
  letter-spacing: -0.016em;
  line-height: 2em;
  color: #2a2b2e;
}

.testimonials-card__footer p {
  font-weight: 600;
  color: #2a2b2e;
}

.testimonials-logo {
  width: 24px;
  height: 24px;
}

.testimonials-rating {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #faa562;
  margin: 0 10px;
  color: #ffffff;
  width: 48px;
  height: 32px;
  border-radius: 32px;
}

.rating img {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.cta {
  padding: 97px 0 85px 0;
  color: #ffffff;
  background-image: url("../img/cta-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cta::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.cta__title {
  position: relative;
  text-align: center;
  max-width: 810px;
  margin: 0 auto;
}

.cta__title img {
  width: 88px;
  margin: 0 auto 33px auto;
}

.cta__title p {
  max-width: 664px;
  margin: 0 auto;
}

.article-section {
  margin: 173px 0 87px;
}

.article {
  color: #2a2b2e;
}

.article h2,
.article h1,
.article .title-1,
.article .title-2 {
  margin-bottom: 31px;
}

.article h6,
.article .title-6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.333em;
  /* 133.333% */
  margin-bottom: 15px;
  margin-top: 40px;
}

.article__title {
  margin-bottom: 88px;
}

.article a {
  font-weight: 500;
  position: relative;
}

.article a::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e01f54;
  position: absolute;
  bottom: 4px;
  left: 0;
}

.article p {
  margin-bottom: 16px;
}

.article p span {
  text-decoration: underline;
}

.article ul {
  margin: 30px 0 50px 0;
}

.article ul li {
  position: relative;
  padding-left: 23px;
}

.article ul li::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #2a2b2e;
  position: absolute;
  top: 14px;
  left: 8px;
  border-radius: 100%;
}

.article ol {
  margin: 30px 0 72px 0;
  counter-reset: my-awesome-counter;
}

.article ol li {
  counter-increment: my-awesome-counter;
  position: relative;
  padding-left: 23px;
}

.article ol li::before {
  content: counter(my-awesome-counter) ". ";
  position: absolute;
  left: 5px;
}

.article table {
  border-collapse: collapse;
  margin-bottom: 40px;
}

.article table tr td,
.article table tr th {
  border: 1px solid #535354;
  padding: 10px 5px;
}

.article table tr td:last-child,
.article table tr th:last-child {
  text-align: center;
}

.blog-section {
  padding: 60px 0 80px;
  background: linear-gradient(180deg, #f4f1f2 0%, #fff 82.81%);
}

.blog-section__title {
  text-align: center;
  margin-bottom: 87px;
}

.blog-section__row {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 1000px;
  column-gap: 88px;
}

.blog-section__col {
  max-width: 50%;
  padding: 0 15px;
  flex: 0 0 450px;
}

.blog-section-card {
  display: flex;
  margin-bottom: 70px;
  width: 100%;
  justify-content: space-between;
}

.blog-section-card:hover p {
  text-decoration: underline;
  text-decoration-color: #e01f54;
  text-decoration-thickness: 2px;
}

.blog-section-card__content {
  margin-right: 16px;
}

.blog-section-card__content p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: -0.25px;
  margin-bottom: 5px;
}

.blog-section-card__content span {
  font-size: 14px;
  line-height: 2.2em;
  letter-spacing: -0.25px;
  color: #535354;
}

.blog-section-card__image {
  width: 116px;
  height: 116px;
  flex: 0 0 116px;
  border-radius: 16px;
  overflow: hidden;
}

.blog-section-card__image img {
  object-fit: cover;
  height: 100%;
}

.blog-section__btns {
  text-align: center;
}

.blog {
  padding: 173px 0 112px;
  background-color: #f4f1f2;
  position: relative;
}

.blog__decor {
  position: absolute;
  top: -80px;
  right: 0;
  max-width: 310px;
}

.blog__title {
  max-width: 560px;
  margin: 0 auto 80px auto;
  text-align: center;
  position: relative;
}

.blog__subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33em;
  margin-bottom: 16px;
}

.blog__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.blog__col {
  padding: 0 16px;
  flex: 0 0 50%;
  max-width: 50%;
}

.blog-card {
  display: flex;
  border-radius: 16px;
  height: 196px;
  overflow: hidden;
  background-color: #ffffff;
  margin-bottom: 32px;
  align-items: center;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.blog-card:hover p {
  text-decoration: underline;
  text-decoration-color: #e01f54;
  text-decoration-thickness: 2px;
}

.blog-card__content {
  padding: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.77em;
  letter-spacing: -0.25px;
  width: 100%;
}

.blog-card__image {
  flex: 0 0 46%;
  overflow: hidden;
  height: 100%;
}

.blog-card__image img {
  height: 100%;
  object-fit: cover;
}

.blog-article {
  max-width: 864px;
  margin: 0 auto;
}

.blog-article h1,
.blog-article .title-1,
.blog-article .title-2 {
  margin-bottom: 16px;
}

.blog-article p {
  margin-bottom: 12px;
}

.blog-article p span {
  text-decoration: none;
  color: #535354;
}

.blog-article img {
  margin: 49px 0;
  border-radius: 16px;
  object-fit: cover;
}

.blog-article img:first-of-type {
  max-height: 574px;
}

.blog-article img:not(:first-of-type) {
  max-height: 380px;
}

.blog-more {
  padding: 80px 0 40px;
  background-color: #f4f1f2;
}

.blog-more__title {
  text-align: center;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1280px) {
  .hero__content {
    padding: 140px 0;
  }

  .hero__img {
    max-width: 240px;
  }

  .info__content {
    margin: 100px 0 0 30px;
  }
}

@media only screen and (max-width: 1024px) {
  h3,
  .title-3 {
    font-size: 42px;
  }

  .blog-section__row {
    column-gap: 0;
  }

  .blog-section__col {
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 992px) {
  .header.fixed .header__nav {
    opacity: 0;
    pointer-events: none;
  }

  .header.fixed .header__nav.active {
    opacity: 1;
    pointer-events: initial;
  }

  .header-row .btns-row {
    padding: 0 16px;
    justify-content: center;
    gap: 16px;
  }

  .header-row .btns-row .btn {
    height: 50px;
  }

  .header__nav {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    margin-left: 0;
    height: 100vh;
    background-color: #f4f1f2;
    pointer-events: none;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  .header__nav.active {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0);
  }

  .header__nav .nav-list {
    flex-direction: column;
    align-items: flex-start;
    padding: 90px 16px 0 16px;
    justify-content: flex-start;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px;
  }

  .header__nav .nav-list__item {
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
  }

  .header__nav .nav-list__link {
    padding-right: 22px;
    position: relative;
    width: 100%;
  }

  .header__nav .nav-list__link::before {
    content: "";
    width: 10px;
    height: 15px;
    position: absolute;
    top: 18px;
    right: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/arrow.svg);
  }

  .nav-list__link,
  .nav-list a {
    padding: 10px 0;
  }

  .burger {
    display: inline-block;
  }

  .hero__content {
    padding: 80px 0;
  }

  .awards__col {
    flex: 0 0 250px;
  }

  .info {
    padding: 13px 0 160px 0;
  }

  .info__row_middle .info__content {
    margin: 180px 0 0 42px;
  }

  .info__img {
    margin: 40px 0 32px 50px;
  }

  .info-card {
    padding: 20px 24px;
  }

  .info__graph {
    max-width: 166px;
  }

  .info__diet {
    bottom: auto;
    top: 90%;
    left: -10%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .diet-card {
    width: 100px;
    height: 100px;
    font-size: 14px;
    padding: 12px 10px;
  }

  .diet-card img {
    width: 20px;
    margin-bottom: 10px;
  }

  .blog__decor {
    display: none;
  }

  .blog__title {
    margin-bottom: 56px;
  }

  .blog__row {
    margin: 0 -8px;
  }

  .blog__col {
    padding: 0 8px;
  }

  .blog-card {
    height: auto;
    flex-direction: column-reverse;
    margin-bottom: 24px;
  }

  .blog-card__content {
    padding: 16px;
  }

  .blog-card__image {
    flex: 0 0 343px;
    order: 0;
    width: 100%;
    height: 343px;
  }

  .blog-article img:first-of-type {
    height: 440px;
  }

  .blog-article img {
    margin: 40px -15px;
    max-width: calc(100% + 30px);
    border-radius: 0;
    width: calc(100% + 30px);
  }

  .blog-more {
    padding: 46px 0 20px;
  }

  .blog-more__title {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 992px) and (max-width: 768px) {
  .header__nav .nav-list__link::before {
    top: 19px;
  }
}

@media only screen and (max-width: 768px) {
  h1,
  .title-2,
  .title-1,
  h2 {
    font-size: 36px;
  }

  h3,
  .title-3 {
    font-size: 36px;
  }

  .btns-row {
    margin: 32px 0 48px 0;
    justify-content: center;
  }

  .header {
    padding: 30px 0 20px;
  }

  .logo {
    width: 120px;
    flex: 0 0 120px;
    margin-top: 0;
  }

  .footer-row {
    padding: 39px 0 40px;
    flex-direction: column;
  }

  .footer-logo {
    margin-bottom: 39px;
    max-width: 109px;
  }

  .footer-menu {
    flex-direction: column;
  }

  .footer-menu__item:not(:last-child) {
    margin-bottom: 32px;
    margin-right: 0;
  }

  .footer_bottom {
    padding: 16px 0;
  }

  .hero {
    padding-bottom: 300px;
  }

  .hero::before {
    height: 70%;
  }

  .hero__content {
    max-width: 100%;
    padding: 43px 0 0 0;
    text-align: center;
    margin-bottom: 130px;
  }

  .hero__content p {
    font-size: 14px;
    margin: 0 auto;
    line-height: 2.28em;
  }

  .hero__content h1,
  .hero__content .title-1,
  .hero__content .title-2 {
    margin-bottom: 10px;
  }

  .hero .btns-row {
    margin: 39px 0;
  }

  .hero__img {
    position: static;
    top: auto;
    margin: 0 auto;
    transform: translate(0, 0);
    bottom: 0;
  }

  .awards {
    padding: 10px 0 2px 0;
    margin-top: -200px;
  }

  .awards__row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .awards__col {
    flex: 0 0 auto;
    width: 100%;
    max-width: 230px;
  }

  .awards-card {
    margin-bottom: 72px;
  }

  .awards-card::before,
  .awards-card::after {
    width: 29px;
    height: 80px;
  }

  .awards-card span {
    font-size: 48px;
    line-height: 0.667em;
    margin-bottom: 2px;
  }

  .awards-card span small {
    font-size: 26px;
  }

  .awards-card p {
    font-size: 14px;
    position: relative;
    bottom: -7px;
  }

  .info {
    padding: 13px 0 56px 0;
  }

  .info__row {
    flex-wrap: wrap;
  }

  .info__row_top {
    margin-bottom: 130px;
  }

  .info__row_middle .info__content {
    margin: 48px 0 0 0;
  }

  .info__row_middle .info__img::after {
    display: none;
  }

  .info__row_middle .info__img {
    margin: 0 18px 0 auto;
  }

  .info__row_middle .info__img::before {
    right: -18px;
  }

  .info__row_middle .info__col:first-child {
    order: 1;
  }

  .info__col {
    flex: 0 0 100%;
  }

  .info__img {
    margin: 0 auto 0 18px;
    max-width: 100%;
  }

  .info__img::before {
    bottom: -18px;
    left: -18px;
  }

  .info__img::after {
    display: none;
  }

  .info__details {
    top: auto;
    bottom: -112px;
    right: 0;
  }

  .info-card {
    width: 216px;
  }

  .info__content {
    max-width: 100%;
    text-align: center;
    margin-top: 145px;
    margin-left: 0;
  }

  .info__graph {
    top: -67px;
    max-width: 70%;
    left: 0;
  }

  .info__diet {
    bottom: -65px;
    top: auto;
    width: 100%;
    left: auto;
    right: 0;
    max-width: 440px;
    justify-content: flex-end;
  }

  .diet-card {
    height: 124px;
    flex: 0 0 132px;
    width: 132px;
    padding: 22px 19px;
    left: 0;
  }

  .diet-card img {
    width: 38px;
  }

  .testimonials__title h2 {
    margin-bottom: 16px;
  }

  .testimonials__title p {
    font-size: 14px;
  }

  .testimonials__content {
    height: auto;
    display: block;
  }

  .testimonials__col {
    height: auto;
    display: block;
  }

  .testimonials__col:not(:nth-child(-n+5)) {
    display: none;
  }

  .testimonials-card {
    margin-bottom: 24px;
    max-width: 100%;
    padding: 12px;
  }

  .testimonials-card__header {
    margin-bottom: 4px;
  }

  .testimonials-card__content p {
    font-size: 14px;
    line-height: 2.28em;
  }

  .testimonials-card__content {
    margin-bottom: 2px;
  }

  .testimonials-card__footer p {
    font-size: 14px;
    line-height: 2.28em;
  }

  .testimonials-logo {
    width: 28px;
    height: 28px;
  }

  .testimonials-rating {
    gap: 9px;
  }

  .cta {
    padding: 72px 0 40px 0;
  }

  .cta__title h2 {
    margin-bottom: 20px;
  }

  .cta__title img {
    margin-bottom: 43px;
  }

  .article-section {
    margin: 120px 0 60px 0;
  }

  .article__title {
    margin-bottom: 20px;
  }

  .article ul {
    margin: 20px 0 50px;
  }

  .blog-section__row {
    justify-content: space-between;
  }

  .blog-section__col {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .blog-section-card {
    margin-bottom: 50px;
  }

  .blog {
    padding: 120px 0 40px 0;
  }

  .blog-card__image {
    flex: 0 0 215px;
  }
}

@media only screen and (max-width: 600px) {
  .blog__col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .info__row_bottom .info__content {
    margin-top: 100px;
  }

  .info__graph {
    max-width: 58%;
  }

  .info__diet {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 400px) {
  .diet-card {
    width: 100px;
    height: 100px;
    flex: 0 0 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .header-row .btns-row .btn {
    height: auto;
  }
}